<template>
  <div>
    <portal to="page-top-title">Subjects</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'subjects-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/subjects" :url-query="{ with: ['category:id,name'] }" data-prop="subjects" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Category</th>
          <th>Value</th>
          <th class="text-center">Has Codes</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="subject in subjects" :key="`subjects-tr-${subject.id}`" :value="subject">
          <td>{{ subject.id }}</td>
          <td>{{ subject.name }}</td>
          <td>
            <router-link :to="{ name: 'subjects-categories-single', params: { id: subject.category.id } }">{{ subject.category.name }}</router-link>
          </td>
          <td>{{ subject.value | textBrief }}</td>
          <td class="text-center">
            <badge-active :value="!!subject.value_with_codes"/>
          </td>
          <td class="text-center">
            <badge-active :value="subject.active"/>
          </td>
          <td class="text-right">{{ subject.created_at }}</td>
          <td class="text-right">{{ subject.updated_at }}</td>
          <td class="text-right">
            <template v-if="subject.active">
              <btn icon="fa-ban" color="danger" @click.stop.native="confirmAction(subject.id, 'deactivate')">Deactivate</btn>
            </template>
            <template v-else>
              <btn icon="fa-check" color="success" @click.stop.native="confirmAction(subject.id, 'activate')">Activate</btn>
            </template>
            <btn :route="{ name: 'subjects-edit', params: { id: subject.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'subjects-single', params: { id: subject.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'
import textBrief from '@/modules/filters/textBrief'
import Action from '@/modules/utils/Action'

export default {
  name: 'SubjectsList',
  metaInfo: {
    title: 'Subjects'
  },
  components: {
    BoxTable,
    BadgeActive,
    Btn
  },
  data () {
    return {
      valueLengthMax: 30,
      subjects: []
    }
  },
  filters: {
    textBrief
  },
  methods: {
    confirmAction (id, action) {
      Action.confirm({
        id: id,
        action: action,
        name: 'Subject',
        route: '/subjects/:id/action',
        collection: this.subjects
      })
    }
  }
}
</script>
